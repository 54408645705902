@import "config";

@media only screen and (min-width: 768px) {
    .about-wrapper {
        margin-top: 80px;
    }
}

.about-wrapper {
    margin-top: 0;
    margin-bottom: 50px;

    .content-wrapper {
        display: grid;
        column-gap: 50px;
        grid-template-columns: 3fr;

        .content {
            grid-column: 1 / 2;

            .text {
                margin-top: 0;
            }
        }
    
        .image {
            grid-column: 2 / 3;
            grid-row: 1 / 3;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
        }  
        .image img {
            max-width: 100%;
            height: auto;
            max-height: 80vh;
            margin: auto auto;
        }

        .icon-button-wrapper {
            display: inline-flex;
            background-color: #BEBACC;
            padding: 10px;
            border-radius: 50%;
            margin-right: 20px;
        }

        .icon-button-wrapper {
            color: white;
            cursor: pointer;
            font-size: 20px;
        }
    }

    @media screen and (min-width: 300px)  {
        .content-wrapper {
            .page-title {
                grid-row: 1 / 2;
            }
            .content {
                grid-column: 1 / 3;
                grid-row: 3 / 4;
            }

            .image {
                grid-column: 1 / 3;
                grid-row: 2 / 3;
            }
        }
    }

    @media screen and (min-width: 768px)  {
        .content-wrapper {
            .image {
                grid-column: 1 / 3;
                grid-row: 2 / 3;
                margin-bottom: 0;
            }
        }
    }


    @media screen and (min-width: 1400px) {
        .content-wrapper {
            grid-template-columns: minmax(500px, 1fr) minmax(600px, 2fr);
            column-gap: 50px;

            .content {
                grid-column: 1 / 2;
                grid-row: 2 / 3;
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                .text {
                    margin-top: -80px;
                }
            }
        
            .image {
                grid-column: 2 / 3;
                grid-row: 1 / 3;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 100px;
                min-height: 80vh;
            }  
        }
    }
}
