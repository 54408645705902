@import "config";

#fp-nav ul li a span {
  background: $purple !important; 
}

.home-wrapper-desktop {
  display: flex;
  align-items: center;

  .left-container {
    display: inline-block;
    width: 50%;
    z-index: 1;
    margin-right: 50px;

    .name-container {
      margin-bottom: 80px;

      h1 {
        font-size: 160px;
        margin: 0;
        line-height: 145px;
      }

    }

    .char {
        display: block;
        color: #BEBACC;
        font-size: 120px;
        line-height: 115px;
        text-transform: uppercase;
        text-shadow: 3px 0 white, 0 3px white, 3px 3px white, 0px -3px white, 3px -3px white, -3px -3px white, -3px 3px white;
    }

    .black {
        color: $dark-grey;
    }

    .subtitle {
      font-family: Lato, sans-serif;
      font-size: 34px;
    }
  }

  .right-container {
    display: inline-block;
    width: 50%;

    .main-image {
      width: 100%;
      max-width: 700px;
      min-width: 600px;
    }
  }
}

.page-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .main-image {
    max-height: 50vh;
  }

  h1 {
    font-size: 50px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .subtitle {
    font-family: Lato, sans-serif;
    font-size: 21px;
  }

  .next-block {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200%;
    bottom: 0;
    height: 52px;
    background-color: $light-purple;
    color: $purple;
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .page-wrapper h1 {
    font-size: 100px;
  }

  .page-wrapper .subtitle {
    font-size: 30px;
  }

  .page-wrapper .main-image {
    max-height: 60vh;
  }
}

@media only screen and (min-width: 900px) {
  .page-wrapper h1 {
    font-size: 90px;
  }

  .page-wrapper .subtitle {
    font-size: 30px;
  }
}

@media only screen and (min-width: 1024px) {
  .page-wrapper h1 {
    font-size: 115px;
  }

  .page-wrapper .subtitle {
    font-size: 33px;
  }

  .page-wrapper .next-block {
    background-color: white;
    color: $light-purple;
  }
}

@media only screen and (min-width: 1600px) {
    .char {
        font-size: 130px !important;
        line-height: 125px !important;
    }
}

@media only screen and (min-width: 1700px) {
    .home-wrapper-desktop {
      margin-left: 100px;
      }
}