@import "config";

.super {
  height: 100vh;
}

.sidebar-closed {
  position: fixed;
  z-index: 21;
  top: 0;
  left: 0;
  overflow-x: hidden;
  height: 100vh;
  background-color: $purple;
  width: 120px;
  border-radius: 0 300% 1300% 0;
  transition: all 600ms cubic-bezier(.9, 0, .33, 1)
}

.sidebar-open {
  position: fixed;
  z-index: 21;
  top: 0;
  left: 0;
  overflow-x: hidden;

  // test
  height: 100vh;
  background-color: $purple;
  width: 565px;
  border-radius: 0 600% 600% 0;
  transition: all 600ms cubic-bezier(.9, 0, .33, 1);
}

.sidebar-content {
  position: absolute;
  top: 20px;
  left: 20px;
}

.sidebar-bg {
  width: 100%;
  height: 100vh;
}

.sidebar-content-open {
  margin-top: 30vh;
  margin-left: 180px;
  transition: all 600ms cubic-bezier(.9, 0, .33, 1);
}

.sidebar-content-closed {
  margin-top: 30vh;
  margin-left: 20px;
  transition: all 600ms cubic-bezier(.9, 0, .33, 1);
}

.nav-link {
  display: inline-block;
}

.nav-links-closed {
  left: -250px;
}

.nav-links-open {
  left: 34%;
}

.nav-links {
  display: flex;
  flex-direction: column;
  position: absolute;
  transition: all 600ms cubic-bezier(.9, 0, .33, 1);
  color: white;
  text-transform: uppercase;
  font-size: 44px;
  letter-spacing: 0.2px;
}

.nav-links a {
  padding: 14px 0 ;
}

.test {
  transition: 1s ease;
}

.topbar {
  width: 40px;
  height: 3px;
  display: block;
  background: white;
  transform: rotate(0);
  transition: all 600ms cubic-bezier(.9, 0, .33, 1);
  .open {
    transform: rotate(45deg);
  }
}

.sidebar-content-open .topbar {
  transform: rotate(45deg);
  width: 55px;
}

.midbar {
  margin-top: 8px;
  width: 55px;
  height: 3px;
  display: block;
  background: white;
  transform: rotate(0);
  transition: all 600ms cubic-bezier(.9, 0, .33, 1);
}

.sidebar-content-open .midbar {
  width: 0;
  top: 22px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(30px);
}

.bottombar {
  margin-top: 8px;
  width: 55px;
  height: 3px;
  display: block;
  background: white;
  top: 20px;
  transform: rotate(0);
  transition: all 600ms cubic-bezier(.9, 0, .33, 1);
}

.sidebar-content-open .bottombar {
  transform: rotate(-45deg);
  margin-top: -13px;
}


/** mobile-nav **/

.mobile-nav {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background: $purple;
  clip-path: circle(70px at 98vw calc(0vh + 13px));
  transition: all 0.5s cubic-bezier(0.66, 0, 0.07, 1);
  z-index: 21;

  .nav-links-wrapper {
    text-align: center;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
  }

  .nav-link {
    text-transform: uppercase;
    color: white;
    padding: 15px 25px;
    font-size: 50px;
  }
}

.mobile-nav.open {
  clip-path: circle(100% at 175px 50vh);
}

.hamburger {
  width: 60px;
  height: 60px;
  top: 5px;
  right: 8px;
  background: $purple;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  z-index: 22;
  position: fixed;

  .open {
    background: white
  }

  .hamburger__patty {
    position: absolute;
    top: 50%;
    left: 60%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 2px;
    width: 65%;
    background-clip: padding-box;
    background: #f7f7f7;
    -webkit-transition: all 0.5s cubic-bezier(0.66, 0, 0.07, 1);
    transition: all 0.5s cubic-bezier(0.66, 0, 0.07, 1);
  }

  .hamburger__patty:nth-child(1) {
    -webkit-transform: translate(-50%, calc(-50% - 8px));
    transform: translate(-50%, calc(-50% - 8px));
    width: 45%;
    left: 70%;
  }

  .hamburger__patty:nth-child(2) {
    transition: all 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  }


    .hamburger__patty:last-child {
    -webkit-transform: translate(-50%, calc(-50% + 8px));
    transform: translate(-50%, calc(-50% + 8px));
  }
}

.hamburger.open {
  .hamburger__patty:nth-child(1) {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
    left: 60%;
    width: 65%;
  }

  .hamburger__patty:nth-child(2) {
    opacity: 0;
  }

  .hamburger__patty:nth-child(3) {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}