@import "config";

@media only screen and (min-width: 768px) {
  .latest-work-wrapper {
    margin-top: 80px;
    color: white;
  }
}

.player-1 {
  grid-column: 1 / 4
}


.player-2 {
  grid-column: 4 / 7
}

.latest-work-wrapper {
  margin-top: 0;
  color: white;

  h4 {
    text-transform: uppercase;
    color: white;
    font-size: 24px;
    margin: 0;
    font-weight: 300;
  }

  .link {
    font-family: lato, sans-serif;
    text-decoration: underline;
    font-weight: 400;
    font-size: 16px;
    display: inline-block;
    margin: 8px 0 0 0;
    cursor: pointer;
  }

  .shape-content {
    padding: 20px;
    height: 100%;

    h4 {
      margin-top: 10px;
    }

    span {
      font-family: Lato, sans-serif;
      font-weight: 300;
      color: #FFFFFF;
      margin: 0;
      display: block;
      overflow: hidden;
    }
  }
}

.wrapper {
  height: 380px;
  position: relative;
  background-color: #CCCCCC;
  border-radius: 12px;
  z-index: 20;
  transition: all .2s cubic-bezier(.42,0,1,1);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

  .main-image {
    width: 100%;
    border-radius: 12px 12px 0 0;
  }
}

.shape {
  clip-path: ellipse(95% 62% at 58% 64%);
  width: 100%;
  height: 0;
  background-color: $purple;
  position: absolute;
  border-radius: 12px;
  z-index: -1;
  transition: all .5s cubic-bezier(.42,0,.58,1);
}

.shape-open {
  height: 120px;
  top: 275px;
  //border-radius: 59% 22% 12px 12px / 9% 5% 12px 12px;
  visibility: initial;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 22;
}

.shape-closed {
  height: 0;
  border-radius: 0;
  top: 380px;
  visibility: hidden;
}

.projects-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 40px;
  margin-bottom: 60px;
}

.project-wrapper {
  .full-description {
    max-width: 50%;
  }

  .image-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 48px;
    margin-bottom: 48px;
  }

  .main-image {
    grid-column: 1 / 3;
  }

  img {
    width: 100%;
    border-radius: 8px;
  }
}

@media only screen and (max-width: 768px) {
  .projects-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .project-wrapper {
    .full-description {
      max-width: 100%;
    }

    .image-grid {
      grid-gap: 20px;
    }

    .main-image {
      grid-column: initial;
    }
  }

  .player-1 {
    grid-column: 1 / 7
  }

  
  .player-2 {
    grid-column: 1 / 7
  }
}
