@import 'styles/config';

@font-face {
  font-family: 'Hansief';
  src: local('Hansief'), url(./fonts/Hansief.otf) format('OpenType');
}

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');

body {
  color: $dark-grey;
  font-family: 'Hansief', sans-serif;
}


h1, h1, h3, h4 {
  color: $dark-grey;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

p {
  font-family: Lato, sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: $dark-grey;
  line-height: 24px;
}

.main {
  margin-left: 200px;
  margin-right: 140px;
}

.page-title-wrapper {
  margin-bottom: 60px;
}

.page-title {
  font-size: 50px;
  color: #484848;
  font-weight: 300;
  margin-bottom: 0;
  text-transform: uppercase;
  margin-top: 12px;
}

.icon-button {
  height: 50px;
  background: none;
  border: none;
  font-size: 34px;
  color: white;
  cursor: pointer;
  outline:none;
}

.back-link {
  font-family: Lato, sans-serif;
  text-decoration: underline;
  font-weight: 600;
  font-size: 20px;
}

.back-link-icon {
  font-size: 24px;
  vertical-align: bottom;
}

@media only screen and (max-width: 768px) {
  .main {
    margin: 0px 20px 0 20px;
  }

  .page-title {
    font-size: 40px;
    padding-bottom: 20px;
    margin-top: initial;
  }

  .page-title-wrapper {
    margin-top: 15px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .page-title-wrapper {
    margin-top: 60px;
  }
}
